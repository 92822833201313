import React from 'react';

import { PropTypes } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Typography } from '@mui/material';

function BulletList(props) {
    const { listItems, icon } = props;
    return (
        <div>
            {listItems.map((t, i) => (
                <Box display="flex" alignItems="center" my={2} key={i}>
                    <FontAwesomeIcon
                        icon={icon}
                        size="1x
                    "
                    />
                    <Typography variant="h6" fontWeight="light" ml={2}>
                        {t}
                    </Typography>
                </Box>
            ))}
        </div>
    );
}

BulletList.propTypes = {};
export default BulletList;
