import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import {
    faMagnifyingGlassChart,
    faThumbTack,
} from '@fortawesome/free-solid-svg-icons';

import BulletList from './BulletList';
import SignUpButton from './SignUpButton';

import { Container, Grid, Hidden, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme: { palette } }) => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    '& > ul': {
        overflow: 'hidden',
        zIndex: -100,
        pointerEvents: 'none',
    },
    '& > ul > li': {
        position: 'absolute',
        display: 'block',
        listStyle: 'none',
        opacity: 0,
        width: 600,
        height: 600,
        borderRadius: '50%',
        background: palette.primary.light,
        animation: 'animate 40s linear infinite',
    },
    '& > ul > li:nth-child(1)': {
        left: '40%',
        animationDelay: '0s',
        animationDuration: '50s',
    },
    '& > ul > li:nth-child(2)': {
        left: '60%',
        animationDelay: '4s',
        animationDuration: '55s',
    },
    '& > ul > li:nth-child(3)': {
        left: '20%',
        animationDelay: '6s',
        animationDuration: '60s',
    },
    '@keyframes animate': {
        '0%': {
            transform: 'translateX(0px) translateY(-500px)',
            opacity: '0',
        },
        '50%': {
            transform: 'translateX(-1000px) translateY(1500px)',
            opacity: '0.25',
        },
        '100%': {
            transform: 'translateX(1000px) translateY(-300px)',
            opacity: '0',
        },
    },
}));

function Banner() {
    return (
        <Container maxWidth="lg" sx={{ py: 10 }}>
            <Typography
                variant="h2"
                align="center"
                color="textSecondary"
                gutterBottom
                mt={3}
            >
                Product Feedback Simplified
            </Typography>

            <Typography variant="h6" align="center" gutterBottom mt={3}>
                Collect, Analyze and Act on Feedback Now
            </Typography>
            <SignUpButton cta="Try For Free" />
            <Grid
                container
                rowSpacing={12}
                columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                sx={{ mt: 10, alignItems: 'center' }}
            >
                <Grid item xs={12} md={5}>
                    <Typography variant="h4" align="center" color="primary">
                        Collect
                    </Typography>
                    <BulletList
                        listItems={[
                            'Setup your feature portal in minutes',
                            'Invite unlimited users to your feedback portal',
                            'Let all users check out and upvote features!',
                        ]}
                        icon={faCommentAlt}
                    />
                    <SignUpButton cta="Sign up now" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Paper
                        elevation={4}
                        sx={{ border: '8px solid', borderColor: 'grey.500' }}
                    >
                        <StaticImage
                            src="../images/collect.png"
                            alt="Dezkr - Product Feedback Simplified"
                            width="800"
                            loading="eager"
                            placeholder="blurred"
                            transformOptions={{ fit: 'contain' }}
                        />
                    </Paper>
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={4}
                            sx={{
                                border: '8px solid',
                                borderColor: 'grey.500',
                            }}
                        >
                            <StaticImage
                                src="../images/analyze.png"
                                alt="Dezkr - Product Feedback Simplified"
                                width="800"
                                loading="eager"
                                placeholder="blurred"
                                transformOptions={{ fit: 'contain' }}
                            />
                        </Paper>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" align="center" color="primary">
                        Analyze
                    </Typography>
                    <BulletList
                        listItems={[
                            'Categorize the different types of feedback',
                            'Talk to users with similar feature requests to surface the “real” problem',
                            'Filter to focus on the problem that needs to be solved',
                        ]}
                        icon={faMagnifyingGlassChart}
                    />
                    <SignUpButton cta="Sign up now" />
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={4}
                            sx={{
                                border: '8px solid',
                                borderColor: 'grey.500',
                            }}
                        >
                            <StaticImage
                                src="../images/analyze.png"
                                alt="Dezkr - Product Feedback Simplified"
                                width="800"
                                loading="eager"
                                placeholder="blurred"
                                transformOptions={{ fit: 'contain' }}
                            />
                        </Paper>
                    </Grid>
                </Hidden>

                <Grid item xs={12} md={5}>
                    <Typography variant="h4" align="center" color="primary">
                        Act
                    </Typography>
                    <BulletList
                        listItems={[
                            'Prioritize and put the feature in the development queue',
                            'Update feedback to let users track progress',
                            'Notify users when feedback is released ',
                        ]}
                        icon={faThumbTack}
                    />
                    <SignUpButton cta="Sign up now" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Paper
                        elevation={4}
                        sx={{ border: '8px solid', borderColor: 'grey.500' }}
                    >
                        <StaticImage
                            src="../images/act.png"
                            alt="Dezkr - Product Feedback Simplified"
                            width="800"
                            loading="eager"
                            placeholder="blurred"
                            transformOptions={{ fit: 'contain' }}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <StyledDiv>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </StyledDiv>
        </Container>
    );
}

export default Banner;
