import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BlogCard from './BlogCard';

import { Box, Button, ButtonBase, Paper, Typography } from '@mui/material';

function FeaturedArticles(props) {
    const { posts } = props;

    return (
        <Box
            display="flex"
            alignItems="stretch"
            flexWrap="wrap"
            justifyContent="space-around"
            p={4}
        >
            {posts.map(({ node }) => renderFeaturedPost(node))}
        </Box>
    );

    function renderFeaturedPost(post) {
        const title = post.frontmatter.title || post.fields.slug;
        const { description = {} } = post.frontmatter;
        const featuredImage = getImage(post.frontmatter.featured_image);

        return (
            <ButtonBase
                component={Link}
                to={`/blog${post.fields.slug}`}
                underline="none"
                key={post.id}
                sx={{
                    m: 1,
                    height: '100%',
                    flex: '1 0 300px',
                }}
            >
                <Paper elevation={1} sx={{ p: 3, height: '100%' }}>
                    <Typography variant="caption">
                        {post.timeToRead} min read
                    </Typography>
                    <Typography variant="h5" mb={3}>
                        {title}
                    </Typography>
                    <Box display="flex" justifyContent="center" mb={1}>
                        <GatsbyImage
                            image={featuredImage}
                            alt={post.frontmatter.image_alt_text}
                        />
                    </Box>
                    <Button endIcon={<FontAwesomeIcon icon={faArrowRight} />}>
                        Read Article
                    </Button>
                </Paper>
            </ButtonBase>
        );
    }
}

export default FeaturedArticles;
