import React from 'react';

import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import Banner from '../components/Banner';
import FeaturedArticles from '../components/FeaturedArticles';
import Footer from '../components/Footer';
import HomePagePMCourse from '../components/HomePagePMCourse';
import Layout from '../components/Layout';
import PricingTable from '../components/PricingTable';
import Seo from '../components/Seo';

import { Box, Container } from '@mui/material/';

function HomePage(props) {
    const { featuredPosts } = props.data;
    return (
        <Layout>
            <Banner />
            <Container maxWidth="lg">
                <PricingTable />
            </Container>
            <Box sx={{ p: 5, bgcolor: 'grey.100' }}>
                <FeaturedArticles posts={featuredPosts.edges} />
            </Box>
            <HomePagePMCourse />
            <Footer />
        </Layout>
    );
}
HomePage.propTypes = {
    data: PropTypes.object,
};

export function Head() {
    return (
        <Seo title="Dezkr Home | Product Feedback Management | Idea Management platform" />
    );
}

export default HomePage;

export const pageQuery = graphql`
    query featuredQuery {
        featuredPosts: allMarkdownRemark(
            sort: { fields: frontmatter___date, order: ASC }
            filter: { frontmatter: { featured: { eq: true } } }
            limit: 8
        ) {
            edges {
                node {
                    id
                    timeToRead
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        image_alt_text
                        featured_image {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, width: 400)
                            }
                        }
                    }
                }
            }
        }
    }
`;
