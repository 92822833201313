import React from 'react';

import { PropTypes } from 'prop-types';

import { Link } from 'gatsby-theme-material-ui';

import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BulletList from './BulletList';

import { Box, Button, Container, Typography } from '@mui/material';

function HomePagePMCourse() {
    return (
        <Container maxWidth="lg" sx={{ p: 4 }}>
            <Typography
                variant="h3"
                align="center"
                color="primary"
                gutterBottom
                mb={5}
            >
                Product Management Fundamentals{' '}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    height: { xs: 700, md: 400 },
                }}
            >
                <Box minWidth={{ md: 400 }} mr={{ md: 4 }}>
                    <Typography variant="h6">
                        A self-paced free online course for
                    </Typography>
                    <BulletList
                        listItems={[
                            'An MBA student looking to break into product management',
                            'A software engineer or any other professional  looking to transition into product management',
                            'Anyone who is curious about product management',
                            'A PM looking for a refresher',
                        ]}
                        icon={faCheck}
                    />
                    <Button
                        component={Link}
                        to={'/pm-course/'}
                        size="large"
                        sx={{ px: 10, my: 4 }}
                        endIcon={<FontAwesomeIcon icon={faArrowRightLong} />}
                    >
                        Begin Course here
                    </Button>
                </Box>
                <iframe
                    src="https://www.youtube.com/embed/3cHNFwLd51o"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                />
            </Box>
        </Container>
    );
}

HomePagePMCourse.propTypes = {};
export default HomePagePMCourse;
