import React from 'react';

import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';

import { truncateString } from '../helper-functions/truncateText';

import { ButtonBase, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function BlogCard(props) {
    const { post, smallCard } = props;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const title = post.frontmatter.title || post.fields.slug;
    const { description = {}, tags } = post.frontmatter;
    const featuredImage = getImage(post.frontmatter.featured_image);

    return (
        <ButtonBase
            key={post.id}
            component={Link}
            to={`/blog${post.fields.slug}`}
            underline="none"
            sx={[
                { p: 3, borderRadius: 4, boxShadow: 3, width: 1, height: 1 },
                theme => ({
                    '&:hover': {
                        backgroundColor: theme.palette.grey[50],
                        boxShadow: 10,
                    },
                }),
            ]}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid
                    item
                    md={4}
                    xs={12}
                    sx={{ justifyContent: 'center', display: 'flex' }}
                >
                    <GatsbyImage
                        image={featuredImage}
                        alt={post.frontmatter.image_alt_text}
                    />
                </Grid>

                <Grid item md={8} xs={12}>
                    <Typography variant="caption">
                        {post.timeToRead} min read
                    </Typography>

                    <Typography
                        variant="h6"
                        gutterBottom
                        noWrap={isSmall || smallCard}
                    >
                        {title}
                    </Typography>

                    <Typography
                        variant="body1"
                        component="div"
                        color="textSecondary"
                    >
                        {isSmall || smallCard
                            ? truncateString(description, 70)
                            : `${description}...`}
                        <Typography color="primary" display="inline">
                            Read More
                        </Typography>
                    </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                    <BlogTag tags={tags} />
                </Grid> */}
            </Grid>
        </ButtonBase>
    );
}

BlogCard.propTypes = {
    post: PropTypes.object,
    smallCard: PropTypes.bool,
};
export default BlogCard;
