import React from 'react';

import { PropTypes } from 'prop-types';

import { signupHref } from '../constants';

import { Box, Button } from '@mui/material';

function SignUpButton(props) {
    const { cta } = props;
    return (
        <Box display="flex" justifyContent="center">
            <Button
                component="a"
                href={signupHref}
                size={'large'}
                color="primary"
                variant="contained"
                sx={{ mt: 2, px: 10, py: 2, bgcolor: 'primary.dark' }}
            >
                {cta}
            </Button>
        </Box>
    );
}

SignUpButton.propTypes = {};
export default SignUpButton;
